import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles/account.scss';

import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../redux/actionCreators';
import { bindActionCreators } from 'redux';

import Post from '../customComponents/Post';

import Image from '../Assets/0y1pvct3kzz21.png';
import sigaret from '../Assets/reaction.mp4';
import startWarsClip from '../Assets/VADER_._DC_Shared_post_with_mando.mp4';
import aughhh from '../Assets/sources/aughhhhh.mp4';
import chadMusic from '../Assets/sources/chad_music.mp4';
import cyberHeadset from '../Assets/sources/cyberpunkHeadset.jpg';
import cyberMoney from '../Assets/sources/cyberpunkMoney.jpg';
import gtaScene from '../Assets/sources/gtaScene.png';
import ripRainbowPlayer from '../Assets/sources/rainbowSixPlayer.jpg';
import sigaretoMix from '../Assets/sources/reactionMix.mp4';
import ripTechno from '../Assets/sources/riptechno.mp4';

import Pfp from '../customComponents/Pfp';

import Loading from '../customComponents/Loading';
import axios from 'axios';
import { serverAddress as proxy, parseSQLArrayProperties, reverseString, filterRepeats, arrayIncludes, removeIndexByValue, removeIndex } from '../custom-modules/customModules';

// custom components
import Badges from '../customComponents/Badges';

// icons
import blockIcon from '../Assets/general/block.png';



function AccountDetails() 
{
  const [accountExists, setAccountExists] = useState(undefined);

  const [userId, setUserId] = useState("");

  let pathname = window.location.pathname;

  if (pathname.charAt(pathname.length - 1) == "/") pathname = pathname.slice(0, pathname.length - 1);
  // console.log(pathname.slice(0, pathname.length - 37))
  if (pathname.slice(0, pathname.length - 37) == "/account" && userId == "") setUserId(pathname.slice(pathname.length - 36, pathname.length));

  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [self, setSelf] = useState("");
  const [followed, setFollowed] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const [banned, setBanned] = useState(false);


  useEffect(() => 
  {
      if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
      else setIsMobile(false);
  }, []); // initializes the window mode from the first enter

  window.addEventListener("resize", () => 
  {
      if (window.innerWidth <= Number(localStorage.getItem("acceptedSize"))) setIsMobile(true);
      else setIsMobile(false);
  });


  const redirect = useNavigate();

  useEffect(() => 
  {
    if ((localStorage.getItem("user") != "" && localStorage.getItem("user") != null) && userId == localStorage.getItem("user")) redirect("/account")
  }, []);


  if (loaded == false)
  {
    axios.post(`${proxy}/getUserById`, { id: userId })
      .then((res) => 
      {
        
        let user = parseSQLArrayProperties(res.data[0]);
        if (loaded == false && localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
        {
          axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
            .then((res) => 
            {
              // console.log(res.data[0])
              let userInfoTemp = parseSQLArrayProperties(res.data[0]);

              setUserInfo(userInfoTemp);
              setUser(user);
              let userRoles = userInfoTemp.roles;
              setRoles(userRoles);

              for (let i = 0; i < userInfoTemp.followed.length; i += 1)
              {
                if (userInfoTemp.followed[i] == user.user_id) setFollowed(true);
                // console.log("followed");
                // console.log(userInfoTemp.followed[i])
              }


            })
            .catch((err) => console.error(err));
        }
        else
        {
          setUser(user);
          setRoles([]);
        }
        

        // console.log(user);

        
          
      axios.post(`${proxy}/banCheck`, { id: userId })
        .then((res) => 
        {
          let isBanned = res.data;
          if (isBanned != undefined) setBanned(isBanned);
          else setBanned(false);

          if (isBanned != true)
          {
            axios.post(`${proxy}/getPostsByUserId`, { id: userId })
              .then((res) => 
              {
                let data = res.data.reverse();
                let allPosts = [];
                for (let i = 0; i < res.data.length; i += 1)
                {
                    allPosts = [...allPosts, {
                      user: "",
                      user_id: data[i].user_id,
                      post_id: data[i].post_id,
                      title: data[i].title,
                      description: data[i].description,
                      source: `${proxy}/src/${data[i].src}`,
                      like: data[i].likes,
                      dislike: data[i].dislikes,
                      boosts: data[i].boosts,
                      type: data[i].type,
                      tags: JSON.parse(data[i].tags),
                      status: data[i].status,
                      date_of_creation: data[i].date_of_creation
                    }];
                }
    
                let users = [];
                data.map((value, index) => users[index] = value.user_id);
                users = filterRepeats(users);
                
                axios.post(`${proxy}/getUsernames`, { users: users })
                    .then((res) => 
                    {
                      let usernames = [];
                      
                      for (let j = 0; j < res.data.length; j += 1)
                      {
                        (usernames.length == 0) ?
                          usernames = [{ id: res.data[j].user_id, username: res.data[j].username }]
                          :
                          usernames = [...usernames, { id: res.data[j].user_id, username: res.data[j].username }];
                      }
    
                      for (let x = 0; x < allPosts.length; x += 1)
                      {
                        for (let y = 0; y < usernames.length; y += 1)
                        {
                          if (allPosts[x].user_id == usernames[y].id) allPosts[x].user = usernames[y].username;
                        }
                      }
    
                      setLoaded(true);
                      setLoadedPosts(allPosts);
                    });
                
                // console.log(allPosts);
              })
              .catch((err) => console.error(err));
          }
          else setLoaded(true);
          
        })
        .catch((err) => console.error(err));
          
          //
      

      if (res.data.length == 0)
      {
        setAccountExists(false);
        setLoaded(true);
        return;
      }
      else if (res.data.length > 1)
      {
        setAccountExists("error");
        setLoaded(true);
        return;
      }
      else
      {
        setAccountExists(true);
      }
      })
      .catch((err) => console.error(err));
  }
  
  const reduxState =  useSelector((state) => state);
  const dispatch = useDispatch();


  const { setCurrentPage } = bindActionCreators(actionCreators, dispatch);


  useEffect(() => 
  {
    setCurrentPage("account");
  }, []);

  function promoteToModerator()
  {
    let userRoles = user.roles;
    (userRoles.length == 0) ?
      userRoles = ["mod"]
      :
      userRoles = [...userRoles, "mod"];
    
    axios.post(`${proxy}/modifyUser`, { id: userId, action: "modifyRoles", property: JSON.stringify(userRoles) })
      .then((res) => 
      {
        // console.log(res.data);
        setLoaded(false);
      })
      .catch((err) => console.error(err));
  }

  function promoteToAdministrator()
  {
    let userRoles = user.roles;
    (userRoles.length == 0) ?
      userRoles = ["admin"]
      :
      userRoles = [...userRoles, "admin"];
    
    axios.post(`${proxy}/modifyUser`, { id: userId, action: "modifyRoles", property: JSON.stringify(userRoles) })
      .then((res) => 
      {
        // console.log(res.data);
        setLoaded(false);
      })
      .catch((err) => console.error(err));
  }



  function follow()
  {
    if (localStorage.getItem("user") == "" || localStorage.getItem("user") == null)
    {
      redirect("/signin");
      return;
    }

    axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
      .then((res) => 
      {
        let data = parseSQLArrayProperties(res.data[0]);
        let follows = data.followed;

        if (arrayIncludes(follows, user.user_id))
        {
          (follows.length == 1) ?
            follows = []
            :
            follows = removeIndexByValue(follows, user.user_id);

            axios.post(`${proxy}/unfollow`, { id: user.user_id })
              .then((res) => 
              {
                axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(follows) })
                .then((res) => 
                {
                    setFollowed(false);
                    setLoaded(false);
                    // console.log(res.data);
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));

        }
        else
        {
          (follows.length == 0) ?
            follows = [user.user_id]
            :
            follows = [...follows, user.user_id];

            axios.post(`${proxy}/follow`, { id: user.user_id })
              .then((res) => 
              {
                axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyFollowed", property: JSON.stringify(follows) })
                .then((res) => 
                {
                    setFollowed(true);
                    setLoaded(false);
                    // console.log(res.data);
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));
        }
      })
      .catch((err) => console.error(err));
  }

  function ban(id)
  {
    localStorage.setItem("ban-id", id.toString());
    redirect("/ban")
  }


  // let allPosts = JSON.parse(localStorage.getItem("test1Post"));

  // function specifyPosts(posts, user)
  // {
  //   let arrangedPosts = [];

  //   for (let i = 0; i < posts.length; i += 1)
  //   {
  //     if (posts[i].user == user)
  //     {
  //       arrangedPosts.length == 0 ?
  //         arrangedPosts = [posts[i]]
  //         :
  //         arrangedPosts = [...arrangedPosts, posts[i]];
  //     }
  //   }
  //   return arrangedPosts.reverse();
    
  // }

  if (loaded == false) return (<Loading />);
  if (banned == true) return (
    <div className="content-page"><img src={blockIcon} style={{ maxWidth: "100px" }} /><h3>This account is banned!</h3></div>
  );
  if (accountExists === false) return (
  <div className="account-page-container" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
    <span style={{ fontWeight: "bold", fontSize: "30px" }}>This account does not exist!</span> <br />
    <span style={{ fontSize: "16px", color: "gray" }}>This account may be banned or it was never created.</span>
  </div>);
  else if (accountExists === "error") return (
    <div className="account-page-container" style={{ display: "flex", alignItems: "center", flexDirection: "column", color: "red" }}>
      <span style={{ fontWeight: "bold", fontSize: "30px" }}>An Error Occured!</span> <br />
      <span style={{ fontSize: "16px", color: "gray" }}>The database detected two accounts with the same properties that should not exist! Or maybe it is just an error and if you refresh the page it would disapear. <br /> <br />If the problem presists, please email us and let us know! <br />memedify@ordious.com</span>
    </div>);
  else if (accountExists !== true && user !== null) return(
    <div className="account-page-container" style={{ display: "flex", alignItems: "center", flexDirection: "column", color: "red" }}>
      <span style={{ fontWeight: "bold", fontSize: "30px" }}>An Error Occured!</span> <br />
    </div>
  );

  return (
    <>
      <div className="account-page-container">
        <div className="account-head" style={(isMobile) ? { flexDirection: "column", justifyContent: "center" } : {}}>
          
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
            <Pfp image={user.pfp} />
            <span className="account-image-container"></span>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", flexDirection: "column" }}>
              <label className="account-name" style={{ fontWeight: "bold", fontSize: "20px" }}>{(user.display_name) == "" ? "" : user.display_name}</label> 
              <label className="account-username">{user.username}</label>
              <label style={{ color: "gray" }}>{user.follows} follower(s)</label>
              {/* <label style={{ color: "gray" }}>{(user.date_of_creation.slice(0, 10).replaceAll("-", "/"))}</label> */}
            </div>
            <Badges roles={user.roles} />
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            {(arrayIncludes(userInfo.roles, "mod")) ? <button className="del-btn" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => ban(user.user_id)}><img src={blockIcon} style={{ width: "40px" }} /></button> : <></>}
            <button className="newpost-follow-btn" onClick={follow}>{(followed) ? "UNFOLLOW" : "FOLLOW"}</button>
          </div>
          
          
        </div>
        <span style={(user.profile_desc.toString().length <= 0) ? { display: "none" } : { display: "flex", justifyContent: "flex-start", alignItems: "center" }}><strong style={{ fontSize: "12px" }}>ABOUT ME:</strong></span>
        <div style={(user.profile_desc.toString().length <= 0) ? { display: "none" } : {}} className="account-description"><span style={{ overflowWrap: "break-word" }}>{user.profile_desc}</span></div>

        <div>
          <div className="account-posts-label-container">
            {/* <label style={{ fontWeight: "bold", fontSize: "25px" }}>Posts from {user.username}:</label> */}
          </div> <br></br>

          <div className="account-posts-container">
            <span style={(loadedPosts.length == 0) ? { display: "block" } : { display: "none" }}>This user never posted!</span>
            {
              loadedPosts.map((value, index) => <div style={{ width: "100%" }}><Post descriptionObject={value} userInfo={userInfo} showFollowButton={false} /></div>)
            }
          </div>
        </div>
      </div>
    </>
  );
}


export default AccountDetails;